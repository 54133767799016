/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-08-14 09:59:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-14 10:02:07
 */
import http from '@/http/axios_init'

/**
 * 获取文件列表
 * @param {*} batchNo 
 * @param {*} group 
 */
export function apiGetFiles(batchNo, group = '') {
	return http.post('admin/v1/public/file', {
		batch_no: batchNo,
		group: group
	})
}
