<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-11 21:05:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-23 11:36:19
-->
<template>
	<el-container class="skin">
		<el-header>
			<div class="bar">
				<div class="logo">
					<img alt="Vue logo" src="@/assets/logo.png" />
					鼎成教育
				</div>

				<div class="menus">
					<ul>
						<li
							v-for="item in topNaves"
							:key="item"
							:class="{ active: item.is_active }"
							@click="onMenuClick(item)"
						>
							{{ item.meta.title }}
						</li>
					</ul>
				</div>

				<div class="profile">
					<el-dropdown>
						<span class="user">
							<el-avatar
								src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
							></el-avatar>
							<label>张三</label>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<el-dropdown-item>
									<el-link href="/profile" :underline="false">个人中心</el-link>
								</el-dropdown-item>
								<el-dropdown-item icon="el-icon-switch-button" @click="onLogout"
									>退出</el-dropdown-item
								>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container>
			<el-aside
				width="200px"
				v-if="currentRootItem && !currentRootItem.hideSide && routes && routes.length"
			>
				<el-scrollbar>
					<side-bar :routes="routes" :activeIndex="activeIndex"></side-bar>
				</el-scrollbar>
			</el-aside>
			<el-main class="fex-column">
				<el-breadcrumb separator="/">
					<!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
					<el-breadcrumb-item v-for="item in breadList" :key="item">{{
						item.meta.title
					}}</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="main-content">
					<router-view></router-view>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import SideBar from '@/layout/sidebar'
import menus from '@/config/menus.js'

export default {
	components: { SideBar },
	data() {
		return {
			// 菜单列表
			topNaves: [],
			routes: [], //左侧菜单路由列表
			currentRootItem: null,
			activeIndex: '',
			//breadcrumb列表
			breadList: []
		}
	},
	watch: {
		/**
		 * 监听路由变化
		 */
		$route() {
			this.getBreadcrumb()
		}
	},
	created() {
		this.current_path = this.$route.path

		menus.forEach((item) => {
			item.is_active = item.urls.includes(this.current_path)
			if (item.is_active) {
				this.routes = item.children
				this.currentRootItem = item
			}
			this.menuRecursion(item, null, item)
		})

		menus.forEach((item) => {
			//如果有权限则添加至头部菜单
			if (item.ignore || item.allow) {
				this.topNaves.push(item)
			}
		})
	},

	mounted() {
		this.getBreadcrumb()
	},

	methods: {
		menuRecursion(item, father, root) {
			if (item.path && item.path != '/') {
				//检查权限 如果有权限则标识
				item.allow = true
				if (father) {
					father.allow = true
				}
				if (!root.redirect) {
					root.redirect = item.path
					root.activeIndex = item.id
				}
				if (item.path == this.current_path) {
					this.activeIndex = item.id
				}
			} else {
				if (item.children && item.children.length > 0) {
					item.children.forEach((child) => {
						this.menuRecursion(child, item, root)
					})
				}
			}

			if (item.allow) {
				if (father) {
					father.allow = true
				}
			}
		},

		/**
		 * 点击菜单
		 * @param {Object} item
		 */
		onMenuClick(item) {
			menus.forEach((item) => {
				item.is_active = false
			})

			item.is_active = true
			this.activeIndex = item.activeIndex
			this.$router.push({ path: item.redirect })
			this.routes = item.children
			this.currentRootItem = item
		},

		/**
		 * 获取breadcrumb
		 */
		getBreadcrumb() {
			if (this.$route.matched) {
				this.breadList = this.$route.matched
			} else {
				this.breadList = []
			}
		},

		/**
		 *
		 */
		onLogout() {
			//
			sessionStorage.removeItem(this.$cfg.TOKEN)
			this.$router.push({ path: '/' })
		}
	}
}
</script>

<style scoped></style>
