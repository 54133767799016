/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-07-11 17:39:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-15 21:09:28
 */
import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import Layout from "@/layout";
import RouteLayout from "@/layout/layout2.vue";
import menus from "@/config/menus.js";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login"),
  }, 
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile"),
  },
];

menus.forEach((item) => {
  item.component = Layout;
  routes.push(item);
});

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  next();
});

/**
 * 后置守卫
 */
router.afterEach(() => {
  NProgress.done();
});

export default router;
