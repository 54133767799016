<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-08-14 09:06:42
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-08 15:20:56
-->
<!--
 * @Description: 表单显示详情
 * @Author: 琢磨先生
 * @Date: 2024-08-14 09:06:42
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-28 10:44:20
-->
<template>
	<el-form v-bind="$attrs">
		<el-form-item :label="item.label" :prop="item.prop" v-for="item in list" :key="item"
			:style="item.col ? 'width:' + item.col : item.width ? 'width:' + item.width : ''">
			<slot :name="item.prop" :row="{ prop: item, value: form[item.prop] }">
				<div v-if="item.type === 'avatar'">
					<!-- 单张图片 -->
					<InfoAvatar :url="form[item.prop]"></InfoAvatar>
				</div>
				<div v-else-if="item.type === 'image'">
					<InfoImage :batch-no="form[item.prop]" :group="item['group']"></InfoImage>
				</div>
				<div v-else-if="item.type === 'file'">
					<InfoFile :batch-no="form[item.prop]" :group="item['group']"></InfoFile>
				</div>
				<div v-else-if="item.type === 'emp'">
					<!--人员-->
					<InfoEmp v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
						:emps="form[item.prop]"></InfoEmp>
				</div>
				<div v-else-if="item.type === 'deptemp'">
					<InfoDeptEmp v-if="
						form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
					" :list="form[item.prop]"></InfoDeptEmp>
				</div>
				<div v-else-if="item.type === 'dept'">
					<InfoDept v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
						:depts="form[item.prop]"></InfoDept>
				</div>
				<div v-else-if="item.type === 'school'">
					<!--校区-->
					<InfoSchool v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
						:schools="form[item.prop]"></InfoSchool>
				</div>
				<div v-else-if="item.type === 'document'">
					<InfoDocument v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
						:documents="form[item.prop]"></InfoDocument>
				</div>
				<div v-else-if="item.type === 'activity'">
					<InfoActivity v-if="
						form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
					" :list="form[item.prop]"></InfoActivity>
				</div>
				<div v-else-if="item.type === 'class'">
					<InfoClass v-if="
						form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
					" :list="form[item.prop]"></InfoClass>
				</div>
				<div v-else-if="item.type === 'select' && !item['multiple']">
					{{
						item['options'] ? item['options'].find((o) => o.value === form[item.prop])?.label : ''
					}}
				</div>
				<div v-else>
					{{ handleView(item) }}
				</div>
			</slot>
		</el-form-item>
	</el-form>
</template>

<script setup>
import InfoFile from './info/File.vue'
import InfoImage from './info/Image.vue'
import InfoAvatar from './info/Avatar.vue'
import InfoEmp from './info/Emp.vue'
import InfoDeptEmp from './info/DeptEmps.vue'
import InfoActivity from './info/Activity.vue'
import InfoClass from './info/Class.vue'
import InfoDept from './info/Dept.vue'
import InfoSchool from './info/School.vue'
import InfoDocument from './info/Document.vue'

const props = defineProps({
	form: {
		type: Object,
		default: {}
	},
	list: {
		type: Array,
		default: []
	}
})

/**
 * 人员资料显示
 */
function handleEmp(emps) {
	if (emps && Array.isArray(emps)) {
		return emps.map((m) => m.name).join('、')
	}
	return ''
}

function handleView(item) {
	if ((item.type === 'select' || item.type === 'radio') && item.options && Array.isArray(item.options)) {
		if (item['multiple'] === true) {
			let a = []
			for (let i = 0; i < item.options.length; i++) {
				if (props.form[item.prop] && props.form[item.prop].length > 0) {
					for (let j = 0; j < props.form[item.prop].length; j++) {
						if (props.form[item.prop][j] === item.options[i].value) {
							a.push(item.options[i].label)
						}
					}
				}
			}
			a = a.join('、')
			console.log(a)
			return a
		} else {
			return item.options.find((x) => x.value === props.form[item.prop])?.label
		}
	}
	if (item.type === 'checkbox' && item.option) {
		return props.form[item.prop] === item.option.trueValue
			? item.option.trueLabel
			: item.option.falseLabel
	}
	if (item.type === 'switch') {
		return props.form[item.prop] === 0
			? '否' : props.form[item.prop] === 1 ? '是' : ''
	}
	return props.form[item.prop]
}
</script>

<style lang="scss" scoped>
.el-form {
	display: flex;
	flex-wrap: wrap;
}

.el-form-item {
	width: 100%;
}

.choose_items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	gap: 5px;
}

.choose_items .item {
	display: flex;
	align-items: center;
	padding: 3px 5px;
	border-radius: 15px;
	background-color: #f5f6f6;
	font-size: 14px;
	line-height: 14px;
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar span {
	position: absolute;
	display: flex;
	align-items: center;
}

.choose_items .el-text {
	margin-left: 3px;
}
</style>
