<template>
	<el-select v-model="model" placeholder="请选择" clearable>
		<el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script setup>
import { ref } from 'vue'
import { apiGetGovjobList } from '@/api/public'
const model = defineModel({ type: Number, default: null })
const dataList = ref([])
apiGetGovjobList().then((res) => {
	console.log(res)
	if (res.code === 200 && res.data) {
		dataList.value = res.data
	}
})
</script>

<style lang="scss" scoped></style>
