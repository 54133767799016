/*
 * @Description:系统设置菜单配置
 * @Author: 琢磨先生
 * @Date: 2024-07-15 12:07:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-18 17:33:00
 */
export default {
	path: '/',
	icon: '',
	component: '',
	meta: {
		title: '系统设置'
	},
	children: [
		{
			path: '/system/function',
			icon: 'Paperclip',
			component: () => import('@/views/system/function'),
			power: 'admin/v1/todo',
			meta: {
				title: 'Api管理'
			}
		},

		{
			path: '/system/permission',
			icon: 'ScaleToOriginal',
			component: () => import('@/views/system/permission'),
			power: 'admin/v1/todo',
			meta: {
				title: '权限策略'
			}
		},
		{
			path: '/system/datafield',
			icon: 'Coin',
			component: () => import('@/views/system/datafield'),
			power: 'admin/v1/todo',
			meta: {
				title: '权限字段'
			}
		},
		{
			path: '/system/tablefield',
			icon: 'Coin',
			component: () => import('@/views/system/tablefield'),
			power: 'admin/v1/todo',
			meta: {
				title: '可选字段配置'
			}
		},
		{
			path: '/system/role',
			icon: 'Pointer',
			component: () => import('@/views/system/role'),
			power: 'admin/v1/todo',
			meta: {
				title: '角色'
			}
		},
		{
			path: '/system/captcha',
			icon: 'Promotion',
			component: () => import('@/views/system/captcha'),
			power: 'admin/v1/todo',
			meta: {
				title: '验证码'
			}
		},
		{
			path: '/system/setting',
			icon: 'Tools',
			component: () => import('@/views/system/setting'),
			power: 'admin/v1/todo',
			meta: {
				title: '参数设置'
			}
		},

		{
			path: '/',
			icon: 'Memo',
			component: '',
			meta: {
				title: '日志管理'
			},
			children: [
				{
					path: '/system/log/error',
					icon: '',
					component: () => import('@/views/system/log/error'),
					power: 'admin/v1/todo',
					meta: {
						title: '错误日志'
					}
				},
				{
					path: '/system/log/request',
					icon: '',
					component: () => import('@/views/system/log/error'),
					power: 'admin/v1/todo',
					meta: {
						title: '请求日志'
					}
				}
			]
		}
	]
}
