<!--
 * @Description: dialog 弹出表单 。如果要每行宽度 list 配置项添加属性 col：'width:100%'； 
 						图片上传、文件上传 list 的 multiple 属性 =true多文件，=false单文件，group 属性为分组；表单的batch_no必填

						展示时 mode='DISPLAY'
						<template #append></template> 附加尾部内容
 * @Author: 琢磨先生
 * @Version: 1.0
 * @Author: 琢磨先生
 * @Date: 2024-07-27 10:11:45
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-28 11:01:22
-->
<template>
	<el-drawer :close-on-click-modal="true" :close-on-press-escape="false" :append-to-body="true"
		:destroy-on-close="true" :draggable="true" v-bind="$attrs" :size="width">
		<div v-loading="loading">
			<slot name="alert"></slot>
			<el-form :model="form" ref="formRef" v-bind="$attrs" v-if="mode === 'DESIGN'">
				<template v-for="item in list" :key="item">
					<template v-if="item.hide !== true">
						<el-form-item :label="item.label" :prop="item.prop"
							:style="item.col ? 'width:' + item.col : item.width ? 'width:' + item.width : ''">
							<slot :name="item.prop" :prop="item" :model="form[item.prop]">
								<el-input v-model="form[item.prop]" v-if="item.type === 'input'"
									:placeholder="item.placeholder ? item.placeholder : '请输入'" :style="item.style"
									:disabled="item.disabled" clearable />
								<el-input v-model="form[item.prop]" type="textarea" v-if="item.type === 'textarea'"
									:placeholder="item.placeholder ? item.placeholder : '请输入'" :style="item.style"
									clearable />
								<el-select v-model="form[item.prop]" v-if="item.type === 'select'"
									:placeholder="item.placeholder ? item.placeholder : '请选择'"
									:multiple="item['multiple']" :filterable="item['filterable']"
									:reserve-keyword="item['reserveKeyword']" :style="item.style" clearable
									@change="selectChange($event, item)">
									<el-option v-for="item in item.options" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
								<el-date-picker v-model="form[item.prop]" type="date" v-if="item.type === 'date'"
									value-format="YYYY-MM-DD" :placeholder="item.placeholder ? item.placeholder : '请选择'"
									:style="item.style" clearable />
								<el-date-picker v-model="form[item.prop]" type="year" v-if="item.type === 'year'"
									value-format="YYYY" :placeholder="item.placeholder ? item.placeholder : '请选择'"
									:style="item.style" clearable />
								<el-date-picker v-model="form[item.prop]" type="month" v-if="item.type === 'month'"
									value-format="YYYY-MM" format="YYYY-MM"
									:placeholder="item.placeholder ? item.placeholder : '请选择'" :style="item.style"
									clearable />
								<el-date-picker v-model="form[item.prop]" type="datetime"
									v-if="item.type === 'datetime'" value-format="YYYY-MM-DD HH:mm"
									:placeholder="item.placeholder ? item.placeholder : '请选择'" :style="item.style"
									clearable />
								<el-time-picker v-model="form[item.prop]" v-if="item.type === 'time'"
									:placeholder="item.placeholder ? item.placeholder : '请选择'" :style="item.style"
									clearable />
								<el-radio-group v-model="form[item.prop]" v-if="item.type === 'radio'"
									:style="item.style" :disabled="item.disabled">
									<el-radio :label="item.label" :value="item.value" v-for="item in item.options"
										:key="item.value">{{ item.label }}
									</el-radio>
								</el-radio-group>
								<el-checkbox v-if="item.type === 'checkbox'" :label="item.option.label"
									:true-value="item.option.trueValue" :false-value="item.option.falseValue"
									v-model="form[item.prop]" :style="item.style"></el-checkbox>
								<el-checkbox-group v-if="item.type === 'checkbox-group'" v-model="form[item.prop]"
									:style="item.style">
									<el-checkbox :label="item.label" v-for="item in item.options" :key="item.value"
										:value="item.value"></el-checkbox>
								</el-checkbox-group>

								<el-switch v-model="form[item.prop]" :active-value="1" :inactive-value="0" inline-prompt
									active-text="是" inactive-text="否" v-if="item.type === 'switch'"
									:style="item.style" />
								<el-slider v-model="form[item.prop]" v-if="item.type === 'slider'"
									:style="item.style" />
								<el-rate v-model="form[item.prop]" v-if="item.type === 'rate'" :style="item.style" />
								<el-color-picker v-model="form[item.prop]" v-if="item.type === 'color'"
									:style="item.style" />
								<el-input-number v-model="form[item.prop]" v-if="item.type === 'number'"
									:placeholder="item.placeholder" :min="item.min" :max="item.max"
									:precision="item.precision" :style="item.style" clearable></el-input-number>

								<el-cascader v-if="item.type === 'cascader'" :options="item.options"
									v-model="form[item.prop]" :style="item.style" clearable></el-cascader>
								<UploadAvatar v-if="item.type === 'avatar'" v-model="form[item.prop]"></UploadAvatar>
								<UploadImage v-if="item.type === 'image'" :batchNo="form[item.prop]"
									:group="item['group'] ? item['group'] : ''" :multiple="item['multiple']">
								</UploadImage>
								<div style="width: 100%" v-if="item.type === 'file'">
									<UploadFile :batchNo="form[item.prop]" :group="item['group'] ? item['group'] : ''"
										:multiple="item['multiple']"></UploadFile>
								</div>
								<ChooseEmp v-if="item.type === 'emp'" v-model="form[item.prop]"
									:multiple="item['multiple']">
								</ChooseEmp>
								<ChoosePost v-if="item.type === 'post'" v-model="form[item.prop]"
									:multiple="item['multiple']">
								</ChoosePost>
								<ChooseDept v-if="item.type === 'dept'" v-model="form[item.prop]"
									:multiple="item['multiple']" :FullName="item['fullname']">
								</ChooseDept>
								<ChooseSchool v-if="item.type === 'school'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseSchool>
								<ChooseDeptEmp v-model="form[item.prop]" v-if="item.type === 'deptemp'"></ChooseDeptEmp>
								<ChooseRole v-if="item.type === 'role'" v-model="form[item.prop]"
									:multiple="item['multiple']">
								</ChooseRole>
								<ChooseGovjob v-if="item.type === 'govjob'" v-model="form[item.prop]"></ChooseGovjob>
								<ChooseRegion v-if="item.type === 'province'" :level="0" v-model="form[item.prop]">
								</ChooseRegion>
								<ChooseRegion v-if="item.type === 'city'" :level="1" v-model="form[item.prop]">
								</ChooseRegion>
								<ChooseRegion v-if="item.type === 'county'" :level="2" v-model="form[item.prop]">
								</ChooseRegion>
								<ChooseDocument v-if="item.type === 'document'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseDocument>
								<ChoosePeople v-if="item.type === 'people'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChoosePeople>
								<ChooseActivity v-if="item.type === 'activity'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseActivity>
								<ChooseHotelRoom v-if="item.type === 'hotelroom'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseHotelRoom>
								<ChooseCurriculum v-if="item.type === 'curriculum'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseCurriculum>
								<ChooseClass v-if="item.type === 'class'" v-model="form[item.prop]"
									:multiple="item['multiple']"></ChooseClass>
							</slot>
						</el-form-item>
					</template>
				</template>
				<slot name="append"></slot>
			</el-form>
			<el-form v-if="mode === 'DISPLAY'" v-bind="$attrs">
				<el-form-item :label="item.label" :prop="item.prop" v-for="item in list" :key="item"
					:style="item.col ? 'width:' + item.col : ''">
					<slot :name="item.prop" :row="{ prop: item, value: form[item.prop] }">
						<div class="image" v-if="item.type === 'avatar'">
							<InfoAvatar :url="form[item.prop]"></InfoAvatar>
						</div>
						<div v-else-if="item.type === 'image'" style="width: 100%">
							<InfoImage :batch-no="form[item.prop]" :group="item['group']"></InfoImage>
						</div>
						<div v-else-if="item.type === 'file'" style="width: 100%">
							<InfoFile :batch-no="form[item.prop]" :group="item['group']"></InfoFile>
						</div>
						<div v-else-if="item.type === 'emp'">
							<InfoEmp v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :emps="form[item.prop]"></InfoEmp>
						</div>
						<div v-else-if="item.type === 'dept'">
							<InfoDept v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :depts="form[item.prop]"></InfoDept>
						</div>
						<div v-else-if="item.type === 'deptemp'">
							<InfoDeptEmp v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :list="form[item.prop]"></InfoDeptEmp>
						</div>
						<div v-else-if="item.type === 'document'">
							<InfoDocument
								v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
								:documents="form[item.prop]"></InfoDocument>
						</div>
						<div v-else-if="item.type === 'activity'">
							<InfoActivity v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :list="form[item.prop]"></InfoActivity>
						</div>
						<div v-else-if="item.type === 'class'">
							<InfoClass v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :list="form[item.prop]"></InfoClass>
						</div>
						<div v-else-if="item.type === 'school'">
							<InfoSchool v-if="
								form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0
							" :schools="form[item.prop]"></InfoSchool>
						</div>
						<div v-else-if="item.type === 'select'">
							{{ handlePreviewSelect(item, form[item.prop]) }}
						</div>
						<div v-else-if="item.type === 'switch'">
							<el-tag v-if="form[item.prop] === 1" type="success">是</el-tag>
							<el-tag v-if="form[item.prop] === 0" type="danger">否</el-tag>
						</div>
						<div v-else-if="item.type === 'radio'">
							{{ item.options[form[item.prop]].label }}
						</div>
						<div v-else>
							{{ form[item.prop] }}
						</div>
					</slot>
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<slot name="footer">
				<el-button type="primary" @click="onConfirm" :loading="saving" v-if="mode === 'DESIGN'">确定</el-button>
			</slot>
		</template>
	</el-drawer>
</template>

<script setup>
import { ref, watch } from 'vue'
import UploadAvatar from './upload/Avatar'
import UploadImage from './upload/Image'
import UploadFile from './upload/File'
import ChooseEmp from './choose/Emp'
import ChoosePost from './choose/Post'
import ChooseDept from './choose/Dept'
import ChooseDeptEmp from './choose/DeptEmp'
import ChooseSchool from './choose/School'
import ChooseRole from './choose/Role'
import ChooseGovjob from './choose/GovJob'
import ChooseRegion from './choose/Region'
import ChooseDocument from './choose/Document'
import ChoosePeople from './choose/People'
import ChooseActivity from './choose/Activity'
import ChooseHotelRoom from './choose/HotelRoom.vue'
import ChooseCurriculum from './choose/Curriculum.vue'
import ChooseClass from './choose/Class.vue'
import InfoFile from './info/File.vue'
import InfoImage from './info/Image.vue'
import InfoAvatar from './info/Avatar.vue'
import InfoEmp from './info/Emp.vue'
import InfoDeptEmp from './info/DeptEmps.vue'
import InfoActivity from './info/Activity.vue'
import InfoClass from './info/Class.vue'
import InfoDept from './info/Dept.vue'
import InfoSchool from './info/School.vue'
import InfoDocument from './info/Document.vue'

const formRef = ref()
const props = defineProps({
	//设计：DESIGN 展示：DISPLAY
	mode: {
		type: String,
		default: 'DESIGN'
	},
	saving: {
		type: Boolean,
		default: false
	},
	form: {
		type: Object,
		default: {}
	},
	/**
	 *  示例 {
	 * 			label:'',
	 * 			type:'' //组件类型 avatar头像 image图片 file文件附件 emp人员 post岗位 dept部门 deptemp school学校 
	 * 					govjob公职类型 province省份 city省市 county省市县 document加油包资料 activity活动 hotelroom酒店房间 curriculum课程 class班级
	 * 		}
	 */
	list: {
		type: Array,
		default: []
	},
	/**
	 * 是否加载数据。修改、查看详情时 需要加载数据
	 */
	loading: {
		type: Boolean,
		default: false
	},

	/**
	 * 宽度
	 */
	width: {
		type: String,
		default: '600px'
	}
})

const emit = defineEmits(['submit'])

const onConfirm = () => {
	formRef.value.validate((valid) => {
		if (valid) {
			emit('submit', props.form)
		}
	})
}

/**
 * 人员资料显示
 */
function handlePreviewEmp(emps) {
	if (emps && Array.isArray(emps)) {
		return emps.map((m) => m.name).join('、')
	}
	return ''
}

function handlePreviewSelect(prop, value) {
	if (prop['options'] && Array.isArray(prop['options'])) {
		if (prop['multiple']) {
			if (value && Array.isArray(value)) {
				return value
					.map((m) => {
						return prop['options'].find((o) => o.value === m)?.label ?? ''
					})
					.join('、')
			} else {
				return ''
			}
		}
		return prop['options'].find((o) => o.value === value)?.label
	}
	return ''
}
</script>

<style scoped>
.el-form {
	display: flex;
	flex-wrap: wrap;
}

.el-form-item {
	width: 100%;
}
</style>
