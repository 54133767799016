<!--
 * @Description: 表格组件，默认宽度150px
 * @Author: 琢磨先生
 * @Date: 2024-08-15 10:26:59
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-11 17:56:49
-->
<template>
	<el-table-column :label="item.label" :prop="item.prop" :resizable="item.resizable ? true : false"
		:sortable="item.sortable" :width="item.minWidth ? 0 : item.width ? item.width : ''" :min-width="item.minWidth"
		:fixed="item.fixed">
		<template #default="{ row }">
			<slot name="default" :row="row" :item="item">
				<div v-if="item.prop === 'batch_no'">
					<dc-info-image v-if="item.type === 'image'" :batch-no="row[item.prop]" :group="item.group"
						:key="row['updated_at']"></dc-info-image>
				</div>
				<div v-else-if="item.type === 'image'">
					<el-image style="width: 60px; height: 60px" :src="row[item.prop]" :zoom-rate="1.2" :max-scale="7"
						:min-scale="0.2" :preview-src-list="[row[item.prop]]" :initial-index="4" fit="cover" />
				</div>
				<div v-else-if="item.type === 'switch'">
					<el-tag v-if="row[item.prop] === 1" type="success">是</el-tag>
					<el-tag v-if="row[item.prop] === 0" type="danger">否</el-tag>
				</div>
				<div v-else-if="item.type === 'select'">
					<div v-for="tag in item.options" :key="tag">
						<el-tag v-if="tag.value === row[item.prop] && tag.type" :type="tag.type">{{ tag.label
							}}</el-tag>
						<span v-if="tag.value === row[item.prop] && !tag.type">{{ tag.label }}</span>
					</div>
				</div>
				<template v-else>
					{{ handleView(row) }}
				</template>
			</slot>
		</template>
	</el-table-column>
</template>

<script setup>
const props = defineProps({
	item: {
		type: Object,
		required: true,
		default: {}
	}
})

function handleView(row) {
	if (!props.item.prop) {
		return ''
	}
	if (
		(props.item.type === 'select' || props.item.type === 'radio') &&
		props.item.options &&
		Array.isArray(props.item.options)
	) {
		if (props.item['multiple'] === true) {
		} else {
			return props.item.options.find((item) => item.value === row[props.item.prop])?.label
		}
	}
	if (props.item.type === 'checkbox' && props.item.option) {
		return row[props.item.prop] === props.item.option.trueValue
			? props.item.option.trueLabel
			: props.item.option.falseLabel
	}
	return row[props.item.prop]
}
</script>

<style lang="scss" scoped></style>
