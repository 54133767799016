/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-08-08 17:41:07
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-08 17:42:52
 */
import http from '@/http/axios_init'

export default {
	/**
	 * 获取公共的系统设置
	 */
	getPublicSetting() {
		return http.get('admin/v1/public/sys_setting')
	}
}
