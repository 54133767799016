<!--
 * @Author: 鱿鱼须 1519964028@qq.com
 * @Date: 2024-08-31 17:52:45
 * @LastEditors: 鱿鱼须 1519964028@qq.com
 * @LastEditTime: 2024-09-02 16:48:01
 * @FilePath: \dc.amin\src\components\dc\choose\ClassRoom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<el-cascader
		v-model="modal"
		:options="list"
		:props="{
			value: 'id',
			label: 'name',
			children: 'children',
			...nativeProps
		}"
		@change="(val) => (modal = val)"
		v-bind="$attrs"
	/>
</template>

<script setup>
import { onMounted, ref, toRefs } from 'vue'
import { apiGetAllClassroom } from '@/api/public'
/**
 * 此组件可以接受全部element-plus的cascader属性放在nativeProps中
 * 也可以接受全部的原生属性
 * 值绑定为v-model="value",即可在父组件中获取选择的值
 */
const modal = defineModel()
const props = defineProps({
	nativeProps: {
		// 原生属性
		type: Object,
		default: () => ({
			checkStrictly: true, // 是否严格的遵循父子节点的选中状态
			emitPath: false // 是否将选中值的路径作为数组返回
		})
	},
	// 是否请求数据,避免一个页面多个选择器请求数据
	isRequest: {
		type: Boolean,
		default: false
	},
	list: {
		type: Array,
		required: (props) => !props.isRequest
	}
})
const emit = defineEmits(['change'])
const { nativeProps, isRequest } = toRefs(props)

const list = ref([])
onMounted(async () => {
	if (isRequest.value) {
		console.log('🚀 ~ onMounted ~ isRequest:', isRequest.value)
		const { data } = await apiGetAllClassroom()
		list.value = data
	}
})
</script>

<style lang="less" scoped></style>
