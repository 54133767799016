<template>
    <el-table-column v-bind="$attrs" label="操作" fixed="right">
        <template #default="{ row }">
            <el-button type="primary" link icon="View" size="small" @click="emit('info', row)" v-if="show[0]">
                详情
            </el-button>
            <el-button link icon="Edit" size="small" @click="emit('edit', row)" v-if="show[1]">
                修改
            </el-button>
            <el-popconfirm :title="delTip" @confirm="emit('delete', row)">
                <template #reference>
                    <el-button link type="danger" icon="Delete" v-if="show[2]" size="small"
                        :loading="row.deleting">删除</el-button>
                </template>
            </el-popconfirm>
            <slot name="append" :row="row"></slot>
        </template>
    </el-table-column>
</template>

<script setup>
/**
 * 此组件是用于给表格添加操作行为的组件，包括编辑、详情、删除、以及自己传入插槽等。
 * show 数组用于控制显示哪些按钮，数组的索引对应按钮的位置，0表示详情，1表示编辑，2表示删除。
 * row 是一个对象，表示当前行的数据。
 * delTip 是一个字符串，表示删除确认弹窗的提示语。
 * 组件通过 emit 函数触发事件，事件名称对应按钮的功能，参数为当前行的数据。
 */
import { toRefs } from 'vue'
const props = defineProps({
    /**
     * 删除确认弹窗的提示语
     */
    delTip: {
        type: String,
        default: '您是否确认删除?'
    },
    /**
     * show 数组用于控制显示哪些按钮，数组的索引对应按钮的位置，0表示详情，1表示编辑，2表示删除。默认 [1, 1, 1]
     * 数组内容 0表示不显示，1表示显示。
     */
    show: {
        type: Array,
        default: () => [1, 1, 1]
    }
})

const emit = defineEmits(['edit', 'info', 'delete'])

</script>
