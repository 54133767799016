/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-07-10 18:08:34
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-08 10:49:42
 */

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import 'nprogress/nprogress.css'
import '@/assets/app.css'
import config from './plugins/in_config'
import router from './plugins/router.js'
import power from './plugins/power.js'
import http from './plugins/http.js'
import 'windi.css'
import App from './App.vue'
import * as dcComponents from './components/dc';
import "@/assets/fonts/index"; // 执行自动引入
import { handlerResult } from './utils/tool'

const app = createApp(App)
app.use(ElementPlus, {
	locale: zhCn
})
app.use(config)
app.use(router)
app.use(power)
app.use(http)

for (const [key, component] of Object.entries(ElIcons)) {
	app.component(key, component)
}
for (const [key, component] of Object.entries(dcComponents)) {
	app.component(key, component)
}

app.mount('#app')
window.handlerResult = handlerResult;