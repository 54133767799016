<!--
 * @Description: 文件附件上传 可设置多个或则单个 group指定不同的分组（各自业务自定义） batchNo批次号uuid值  通过 import {v4} from 'uuid' 创建
 * @Author: 琢磨先生
 * @Date: 2024-08-09 14:19:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-23 19:44:06
-->
<template>
	<el-upload ref="upload" v-model:file-list="fileList" action="" :multiple="is_multi" :limit="limit"
		:http-request="handleUpload" :on-remove="handleRemove" :on-exceed="handleExceed">
		<slot>
			<el-button type="default" icon="Upload">上传文件</el-button>
		</slot>
	</el-upload>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { uploadFile, FileTypes } from '@/utils/upload_util'
import setting_api from '@/api/setting'
import http from '@/http/axios_init'

const props = defineProps({
	/**
	 * 批次号
	 */
	batchNo: {
		type: String,
		required: true,
		default: ''
	},
	group: {
		type: String,
		default: ''
	},
	// 是否多图上传
	multiple: {
		type: Boolean,
		default: true
	}
	// limit: {
	// 	type: Number,
	// 	default: 999
	// }
})
const limit = ref(1)
const is_multi = ref(true)
const fileList = ref([])
const upload = ref()
var settings = {}
const emit = defineEmits(['change'])

setting_api.getPublicSetting().then((res) => {
	if (res.code === 200) {
		settings = res.data
	}
})

// onMounted(() => {
// 	setting_api.getPublicSetting().then((res) => {
// 		if (res.code === 200) {
// 			settings = res.data
// 		}
// 	})
// })

watch(
	()=>props.batchNo,
	(newVal, oldVal) => {
		if (newVal) {
			http
				.post('admin/v1/public/file', {
					batch_no: newVal,
					group: props.group
				})
				.then((res) => {
					if (res.code === 200) {
						if (res.code === 200 && res.data) {
							fileList.value = res.data.map((item) => {
								return {
									id: item.id,
									name: item.name,
									url: item.file_url
								}
							})
						}
					}
				})
		}
	},
	{
		immediate: true
	}
)

watch(
	()=>props.multiple,
	(newVal) => {
		if (newVal) {
			limit.value = 999
			is_multi.value = true
		} else {
			limit.value = 1
			is_multi.value = false
		}
	},
	{
		immediate: true
	}
)

/**
 * 上传文件
 * @param ctx
 */
function handleUpload(ctx) {
	console.log(ctx)
	ctx.onProgress({
		percent: 0
	})
	uploadFile(ctx.file, FileTypes.FILE, settings, {
		progress: (p, cpt, res) => {
			ctx.onProgress({
				percent: parseFloat((p * 100).toFixed(1))
			})
		}
	})
		.then((res) => {
			var model = {
				...res,
				batch_no: props.batchNo,
				group: props.group,
				is_overwrite: props.multiple ? 0 : 1 //如果是单图则覆盖原有的数据
			}
			http.post('admin/v1/public/file/add', model).then((r) => {
				if (r.code === 200) {
					ctx.onSuccess()
					emit('change')
					if (r.data) {
						var f = fileList.value.find((o) => o.uid == ctx.file.uid)
						if (f) {
							f.id = r.data.id
						}
					}
				} else {
					ctx.onError({})
				}
			})
		})
		.catch((err) => {
			ctx.onError()
		})
}

/**
 * 移除文件，删除
 * @param file
 */
function handleRemove(file) {
	if (file.id) {
		http.post('admin/v1/public/file/del', { id: file.id }).then((res) => {
			if (res.code === 200) {
				emit('change')
			}
		})
	}
}

/**
 * 预览
 * @param file
 */
function handlePictureCardPreview(file) {
	// console.log(file)
}

/**
 * 如果单个图片上传则覆盖
 * @param files
 */
function handleExceed(files) {
	if (!props.multiple) {
		upload.value.clearFiles()
		upload.value.handleStart(files[0])
		upload.value.submit()
	}
}
</script>

<style scoped>
:deep(.el-upload--picture-card) {
	--el-upload-picture-card-size: 120px;
}

:deep(.el-upload-list--picture-card) {
	--el-upload-list-picture-card-size: 120px;
}
</style>
